<template>
  <div class="live">


    <div class="content">
      <!-- 顶部 -->
      <Header :borderShow="true" titleName="直播专区" />
      <div class="cont">
        <van-loading color="#888888" v-if="data.loading" vertical style="margin-top: 20vh;">加载中...</van-loading>



        <div class="list_empty" v-if="data.liveList.length == 0 && !data.loading">
          <img src="@/assets/images/list_empty.png" alt="">
          <div class="first_div">暂无内容~</div>
          <div class="last_div">请刷新页面或稍后尝试~</div>
        </div>





        <div v-if="data.liveList.length > 0 && !data.loading">
          <div class="li" v-for="(item, index) in data.liveList" :key="index" @click="toNewDetails(item.id,item.linkUrl,item.content)">
           
			<div v-if="item.linkUrl && item.linkUrl.indexOf('weixin.qq.com') != -1">
						<img v-if="item.ewm" class="ewm" :src="item.ewm" alt="" style="margin-top: .28rem;">
						<div style="color: #ACACAC;font-size:14px" v-else>敬请期待</div>
				<div style="color: #ACACAC;font-size:.32rem;font-weight: 800;margin-top: .48rem;" v-if="item.ewm">请保存截图使用微信扫码观看直播回放</div>
		
				
			</div>
			<div v-else>
				<img class="ewmcover" :src="item.imgUrl" alt="" style="margin-top: .1rem;border:1px solid #ccc;box-shadow: 5px 5px 5px #ccc;">
				
			</div>
            
            <div class="title fw6" style="font-size: .48rem;margin-top: .28rem;text-align: left;">
              {{ item.title }}
			  <div style="color: #ACACAC;font-size: .32rem;margin-top: .28rem;text-align: left;">{{ item.zhaiyao }}</div>
            </div>
            
          </div>
        </div>

      </div>





    </div>
    <!-- 底部tab切换 -->
    <!-- <Footer /> -->
  </div>
</template>

<script setup>
import Footer from "../../components/Footer.vue";
import { reactive, toRefs, onMounted } from "vue";
import Header from "../../components/Header.vue";
import Api from "../../utils/api";
import qrcode from 'qrcode'
import { jsToCallApp } from '../../common/js/callApp1.0.0'
import { useRoute, useRouter } from 'vue-router'
const route = useRoute()
const router = useRouter()

let data = reactive({
  swiper: [],
  current: 0,
  liveList: [],
  loading: true,
  index: 0,
  postData: {
    categoryId: 13,
    channelId: 1,
    pageSize: 100,
    pageIndex: 1,
  },
  imgurl: '',
});


onMounted(() => {
  getNwesList()
  shareFn()
});

const shareFn = () => {
  const jsonString = {
    shareUrl: 'https://quanzong.cwmia.com/#/member/live',//分享链接
    shareCorver: 'https://quanzong.cwmia.com/logo.png',
    shareTitle: '中国职工保障互助会',
    shareDesc: '直播专区',//分享描述
  }
  openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
}

function openAppFunction(type, jsonData) {
  const useTimeOut = setTimeout(() => {
    jsToCallApp(
      {
        type: type,
        ...jsonData,
      },
      (data) => {
        console.log(type, data)
      },
    )
    clearTimeout(useTimeOut);
  }, 300);
}


// 活动专区 - 新闻列表
const getNwesList = () => {
  Api.articleViewChannelNew(data.postData).then(res => {

    if (res.status == 200) {
      data.liveList = res.data
		//console.log(JSON.stringify(data.liveList));

      data.index = data.liveList.length
      if (data.liveList.length == 0) {
        data.loading = false
      }
      dg()
    } else {
      data.loading = false
    }
  })
}



const generateQRCode = (linkUrl, i) => {
  console.log('-++++++++++', linkUrl, i)
  if (!linkUrl) {
    if (i == 0) {
      data.loading = false
    }
    data.index--
    if (data.index > 0) {
      dg()
    }
    return
  }
  qrcode.toDataURL(linkUrl, { width: 400 }).then(url => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const logo = new Image();
    logo.src = require('@/assets/images/member/logo.png'); // 替换为你的logo图片路径
    function drawRoundRect(ctx, x, y, width, height, radius) {
      ctx.beginPath();
      ctx.moveTo(x - width / 2 + radius, y - height / 2);
      ctx.lineTo(x + width / 2 - radius, y - height / 2);
      ctx.arcTo(x + width / 2, y - height / 2, x + width / 2, y - height / 2 + radius, radius);
      ctx.lineTo(x + width / 2, y - height / 2 + radius);
      ctx.lineTo(x + width / 2, y + height / 2 - radius);
      ctx.arcTo(x + width / 2, y + height / 2, x + width / 2 - radius, y + height / 2, radius);
      ctx.lineTo(x + width / 2 - radius, y + height / 2);
      ctx.lineTo(x - width / 2 + radius, y + height / 2);
      ctx.arcTo(x - width / 2, y + height / 2, x - width / 2, y + height / 2 - radius, radius);
      ctx.lineTo(x - width / 2, y + height / 2 - radius);
      ctx.lineTo(x - width / 2, y - height / 2 + radius);
      ctx.arcTo(x - width / 2, y - height / 2, x - width / 2 + radius, y - height / 2, radius);
      ctx.closePath();
    }

    logo.onload = () => {
      canvas.width = canvas.height = 400
      const ewm = new Image();
      ewm.src = url
      ewm.onload = () => {

        ctx.drawImage(ewm, 0, 0, 400, 400);
        drawRoundRect(ctx, 200, 200, 90, 90, 8);
        ctx.fillStyle = "#ffffff";
        ctx.fill();

        if (i == 0) {
          data.loading = false
        }

        ctx.drawImage(logo, 140, 140, 120, 120);
        data.liveList[i].ewm = canvas.toDataURL()
        console.log(data.liveList)
        data.index--
        if (data.index > 0) {
          dg()
        }
      }
    };

  })
}


const dg = () => {
  console.log(data.index)
  generateQRCode(data.liveList[data.index - 1].linkUrl, data.index - 1)
}

const toNewDetails = (id,linkUrl,content) => {
	if (content && content.indexOf('<video')!=-1) {
		  //直播回放
		  router.push({ path: '/news/details', query: { id, }})
	} else {
		if (linkUrl) {
			if (linkUrl.indexOf('weixin.qq.com')!=-1) {
				//微信直播
			} else {
				//外部链接
				window.location.href = linkUrl;
			}
		}
	}
};
</script>

<style lang='less' scoped>
/deep/ .van-tabs__wrap {
  border-radius: 10px;
}



.live {
  display: flex;
  flex-flow: column;
  height: 100%;
  font-size: 12px;

  .content {
    flex: 1;
    overflow-y: auto;
    background-color: #fff;

    .cont {
      padding: 0 .4rem;
    }

    .list_empty {
      text-align: center;
      padding-top: 15vh;

      img {
        width: 6.2933rem;
        height: 4.52rem;
      }

      .first_div {
        font-size: .4667rem;
        font-weight: 500;
        margin-top: .6667rem;
      }

      .last_div {
        font-size: .36rem;
        color: #ACACAC;
        margin-top: .2rem;

      }
    }

    .li {
      border-bottom: #ececec solid .0133rem;
      padding: .4667rem 0;
      text-align: center;

      >div {
        
      }

      .ewm {
        width: 6rem;


      }
	  .ewmcover{
		  width: 98%;
		  border-radius: .10rem;
	  }
    }
  }
}
</style>